/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

const ContentCSS = css`
  color: ${(props) => props.theme.color.text.light};
 // background: ${(props) => props.theme.color.text.light};
  font-size: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.secondary};

  h2,
  h3 {
    font-size: ${(props) => props.theme.font.size.xl};
    font-weight: ${(props) => props.theme.font.weight.l};
    font-family: ${(props) => props.theme.font.family.main};
  }
`

const Content = styled(ParseContent)`
  ${ContentCSS};
`

const ContentPrivacy = styled.div`
  ${ContentCSS};
`

const PageTemplate = ({
  data: {
    page: { path, title, content, yoast_meta: yoast, wordpress_id: wordpressId },
  },
}) => (
  <Layout>
    <SEO yoast={yoast} path={path} />

    <div className="container py-5">
      <TitleDefault>{title}</TitleDefault>
      {wordpressId === 3 ? (
        <ContentPrivacy className="py-5 mb-3 mb-lg-5">
          <script
            id="CookieDeclaration"
            src="https://consent.cookiebot.com/aee863b1-3324-433c-ae3c-2db895de0df3/cd.js" 
            type="text/javascript"
            async
          />
        </ContentPrivacy>
      ) : (
        <Content className="py-5 mb-3 mb-lg-5" content={content} />
      )}
    </div>
  </Layout>
  )

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      wordpress_id
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
